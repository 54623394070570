.chatPhone {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  height: 85%;
  border-radius: 20px;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
}

.position {
  position: absolute;
  right: 0;
}
.ionColor {
  color: #78d23c;
}

.ionBgColor {
  background-color: #78d23c;
}
.chatFlex {
  flex: 1;
  overflow: auto;
  padding: 0;
}

.message-container {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
  margin: 5px 0;
}

.user-message {
  background-color: #e0f7fa;
  color: #000;
  margin-left: auto;
  max-width: 70%;
  text-align: right;
}

.bot-message {
  background-color: #78d23c;
  color: #000;
  text-align: left;
}

.display {
  display: flex;
  justify-content: right;
}

.bg-color {
  --background: #78d23c;
  --background-hover: #8af643a6;
  border-radius: 50%;
}

.rotate {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.size {
  font-size: 36px;
}
