.ion-text-small {
  font-size: 0.8em;
}

.ion-text-medium {
  font-size: 0.93em;
}

.ion-text-large {
  font-size: 1.5em;
}

.ion-text-capitalize-first {
  display: block;
  text-transform: lowercase;
}

.ion-text-capitalize-first::first-letter {
  text-transform: uppercase;
}

#homepage .homeBtn {
  box-shadow: 2px 4px 6px black;
  border-radius: 22px;
  cursor: pointer;
}
#homepage .homeBtn:hover {
  filter: brightness(1.1);
}

#homepage .btn {
  padding: 10px;
}

/*new css*/

#homepage .newbtn {
  padding: 10px;
}

#homepage .newbtn:hover {
  filter: brightness(1.1);
  cursor: pointer;
}

#homepage a {
  color: #1558cc;
  font-weight: 600;
  font-family: 'Varela Round', 'Ralewa', Arial, sans-serif;
  cursor: pointer;
}

#homepage .newbtn:hover a {
  color: var(--ion-color-primary, #3880ff);
}

#homepage a:hover {
  color: var(--ion-color-primary, #3880ff);
}

.same-height {
  color: red;
  display: flex;
  flex-wrap: wrap; /* Per mantenere il comportamento responsivo */
}

.same-height > .ion-col {
  display: flex; /* Rende il contenuto della colonna flessibile */
  flex-direction: column; /* Allinea i figli in verticale */
}

.same-height > .ion-col > ion-card {
  flex: 1; /* Allunga la card per riempire tutta l'altezza */
  display: flex;
  flex-direction: column; /* Mantiene la struttura delle card */
}
.ion-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuisce i contenuti */
  height: 100%;
}

.ion-card-description {
  flex-grow: 1; /* Occupa lo spazio rimanente */
}

.welcome-card {
  margin: 16px;
  border-radius: 12px;
  border: 1px solid #00000036;
}

.welcome-card h1 {
  font-size: 24px;
  margin-bottom: 16px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-info p > span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  text-align: center;
}

.service-item ion-icon {
  font-size: 30px;
  color: white;
}

.purple {
  background: #9f87f7;
}

.yellow {
  background: #fec107;
}

.blue {
  background: #3dc3d1;
}

.coral {
  background: #ff6e41;
}

.green {
  background: #78d23c;
}

.yellow-bg {
  background: #c9d651;
}

.coral-bg {
  background: #ffccbc;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 20px;
}

.stat-card {
  padding: 10px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom:45px;
}

.card-row {
  text-align: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 35px;
}

.stat-card h3 {
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 6px;
  color: black;
  font-weight: 600;
}

.stat-card h4 {
  font-size: 16px;
  margin-bottom: 0.5px;
  margin-top: 6px;
  color: black;
  font-weight: bolder;
}

.stat-card h2 {
  font-size: 24px;
  margin-bottom: 8px;
  color: black;
}

.stat-card p {
  margin: 0;
  font-weight: lighter;
}

.stat-card-row {
  display: flex;
  align-items: stretch;
}

.fontP {
  font-size: medium;
  font-weight: bold;
}

.fontPBotton {
  font-size: 14px;
  text-align: center;
  margin-top: 3px;
}

.text {
  text-align: start;
}

.m-top-zero {
  margin-top: 0;
}

.m-top {
  margin-top: 6px !important;
}

.pd-bottom {
  padding-bottom: 0;
}

.pd-right {
  margin-right: 13px;
}

.mg-r-t {
  margin-right: 8px;
  margin-top: 10px;
}

.mg-l-t {
  margin-left: 8px;
  margin-top: 10px;
}

.m-bottom {
  margin-bottom: 15px;
}

.pd {
  padding: 4px;
  margin-top: 10px;
}

.pd-top {
  padding-top: 10px;
}

.m-buttons{
  margin-left: 8px;
  margin-right: 10px;
}

.width {
  white-space: normal;
  word-break: keep-all;
  overflow-wrap: break-word;
  width: 100%;
  height: auto;
}

.border {
  padding: 5px;
  border-radius: 30px;
  background-color: white;
  text-align: center;
  font-weight: bolder;
  color: black;
  font-size: 15px;
  padding: 7px;
}

.display-flex {
  display: flex;
  justify-content: center;
}

.img-home-top {
  margin: 16px;
}

.img-home-top img {
  border-radius: 10px;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  margin: 16px;
  border-radius: 12px;
  border: 1px solid #00000036;
}

.card-slider {
  width: 100%;
  height: 215px;
  max-height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-slider-home {
  width: 100%;
  max-height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-home {
  height: 100%;
  width: 100%;
}

.swiper-slide img {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
}

.icon-medium {
  font-size: 24px;
}

@media only screen and (min-width: 900px) {
  .height {
    height: 50%;
  }

  .img-max {
    object-position: center;
    object-fit: cover;
  }

  .height-width {
    height: 50%;
    width: 100%;
  }
}
